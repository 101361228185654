import {
  Button,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import Search from "antd/es/input/Search";
import { Fragment, useContext, useReducer, useState } from "react";
import { createUser, deleteUser, updateUser } from "../api";
import { KeycloakContext } from "../KeycloakProvider";
import { APP_ADMIN } from "../constants";
import UserRole from "../components/UserRole";
import { useRealmRoles, useUser } from "../components/Hooks";
import UserForm from "../components/forms/UserForm";

const { Title } = Typography;
const columns = (
  page: number,
  pageSize: number,
  openEdit: any,
  onDelete: any,
  openRole: any
) => [
  {
    title: "username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "firstName",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "tenant",
    dataIndex: "attributes",
    render: (_: any, record: any) => {
      if (record.attributes && record.attributes["tenant"]) {
        return record.attributes["tenant"];
      }
      return null;
    },
  },
  {
    title: "lastName",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "groups",
    dataIndex: "groups",
    key: "groups",
    render: (_: any, record: any) => {
      if (record.groups) {
        return record.groups?.map((g: string, i: number) => {
          return <Tag key={i}>{g}</Tag>;
        });
      } else {
        return null;
      }
    },
  },
  {
    title: "action",
    key: "action",
    render: (text: any, record: any) => {
      return (
        <Fragment>
          <Button onClick={() => openEdit(record)} size="small">
            Edit
          </Button>
          <Button onClick={() => openRole(record)} size="small">
            Role
          </Button>
          <Popconfirm
            placement="top"
            title={`Delete "${record.username}" ?`}
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </Fragment>
      );
    },
  },
];

const User = (props: any) => {
  const { token, hasRealmRole, tenants } = useContext(KeycloakContext);

  const [state, updateState] = useReducer(
    (state: any, action: any) => {
      switch (action.type) {
        default:
          return { ...state, ...action };
      }
    },
    {
      page: 1,
      max: 10,
      search: "",
      last: "",
    }
  );

  const { loading, list, dispatch } = useUser({ token, state });

  const [saving, updateSaving] = useState(false);
  const saveUser = async (values: any) => {
    console.log("values", values);

    updateSaving(true);

    try {
      if (values.id === undefined) {
        const result = await createUser({ ...values, token });
        console.log("result===", result);

        if (result?.id) {
          // success
          closeForm();
          // updateTotal((total) => total + 1)
          updateState({ last: new Date().getTime() }); //force refresh
        } else {
          message.error(<div>{result?.error?.message || "Алдаа гарав"}</div>);
        }
      } else {
        const result = await updateUser({ ...values, token });

        if (result?.success) {
          // success
          closeForm();
          dispatch({ type: "merge", value: values });
        } else {
          message.error(<div>{result?.error?.message || "Алдаа гарав"}</div>);
        }
      }
    } catch (err: any) {
      console.log("err", err);
      message.error(<div>{err?.message || "Алдаа гарав"}</div>);
    }

    updateSaving(false);
  };

  const [selected, updateSelected] = useState<any>();
  const [showForm, updateShowForm] = useState(false);

  const addUser = () => {
    updateSelected(undefined);
    updateShowForm(true);
  };

  const openEdit = (record: any) => {
    updateSelected(record);
    updateShowForm(true);
  };

  const closeForm = () => {
    if (saving) {
      return;
    }
    updateShowForm(false);
    updateSelected(undefined);
    // form.resetFields();
  };

  const handleName = (val: string) => {
    // console.log("handle search s val===", val);
    updateState({ page: 1, search: val });
  };

  const onDelete = async (selected: any) => {
    try {
      const id = selected.id;

      const result = await deleteUser(id, token);

      if (result?.success) {
        dispatch({ type: "delete", value: id });

        // updateTotal(total => total - 1)
      } else {
        message.error(<div>{result?.error?.message || "Error occured"}</div>);
      }
    } catch (err: any) {
      console.log("err", err);
      message.error(<div>{err?.message || "Error occured"}</div>);
    }
  };

  const changeTenant = (value: string) => {
    updateState({ page: 1, tenant: value });
  };
  const clearTenant = () => {
    updateState({ page: 1, tenant: undefined });
  };

  const [showRole, updateShowRole] = useState(false);

  const openRole = (record: any) => {
    updateSelected(record);

    updateShowRole(true);
  };

  const closeRole = () => {
    if (saving) {
      return;
    }
    updateShowRole(false);
    updateSelected(undefined);
  };

  const { roles: realmRoles } = useRealmRoles({ token });

  return (
    <Fragment>
      <div className="page-header">
        <p className="header-label">
          <Title level={2}>User list</Title>
        </p>
      </div>
      <div className="page-body">
        <div className="page-body-header flex">
          <Space align="start" size="large">
            {tenants !== undefined &&
              hasRealmRole &&
              hasRealmRole(APP_ADMIN) && (
                <Select
                  allowClear
                  placeholder="select tenant"
                  onClear={clearTenant}
                  style={{ width: "200px" }}
                  options={tenants.map((t: string) => ({ label: t, value: t }))}
                  onChange={changeTenant}
                />
              )}

            <Search
              allowClear
              placeholder="Хайх үгээ оруулна уу..."
              onSearch={handleName}
              enterButton
            />
          </Space>
          <Button type="primary" style={{ float: "right" }} onClick={addUser}>
            Add User
          </Button>
        </div>
        <Table
          rowKey={"id"}
          loading={loading}
          dataSource={list}
          columns={columns(
            state?.page || 1,
            state?.max || 10,
            openEdit,
            onDelete,
            openRole
          )}
          pagination={false}
          // pagination={{
          //     current: state?.page,
          //     total: total,
          //     pageSize: state?.per_page,
          //     showTotal: (total, range) => `Нийт ${total}`,
          //     onChange,
          //     onShowSizeChange,
          // }}
        />
      </div>
      <Drawer
        width={600}
        placement="right"
        closable={true}
        onClose={closeForm}
        open={showForm}
      >
        <p
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          <Title level={3}>User {selected ? "edit" : "add"}</Title>
        </p>
        <Divider />
        {showForm && (
          <UserForm selected={selected} saving={saving} save={saveUser} />
        )}
      </Drawer>
      <Drawer
        width={600}
        placement="right"
        closable={true}
        onClose={closeRole}
        open={showRole}
      >
        <p
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          User Role
        </p>
        <Divider />
        {selected && (
          <UserRole
            userId={selected?.id}
            allRoles={realmRoles}
            saving={saving}
            updateSaving={updateSaving}
          />
        )}
      </Drawer>
    </Fragment>
  );
};

export default User;
