import { Fragment, useContext } from "react";
import { KeycloakContext } from "../KeycloakProvider";
import { useParams } from "react-router-dom";
import { useGroupMember } from "../components/hooks/Group";
import { Table } from "antd";

const columns = [
  {
    title: "username",
    dataIndex: "username",
    key: "username",
  },
];

const GroupMember = (props: any) => {
  const { token } = useContext(KeycloakContext);
  const { groupId } = useParams();

  const { loading, list } = useGroupMember({ token, id: groupId });
  return (
    <Fragment>
      <Table
        rowKey={"id"}
        loading={loading}
        dataSource={list}
        columns={columns}
        pagination={false}
      />
    </Fragment>
  );
};

export default GroupMember;
