import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import KeycloakProvider from './KeycloakProvider';

const App = (props:any) => {

  useEffect(() => {
   
  }, [])

  return (
    <BrowserRouter>
      <KeycloakProvider>
        {/* <StrictMode> */}
          <AppRoutes/>
        {/* </StrictMode> */}
      </KeycloakProvider>
    </BrowserRouter>
  );
}
export default App