import { Button, Form, Input } from "antd";
import { useEffect } from "react";
import JsonField from "./JsonField";
import { encodeJsonAttr } from "../Utils";

const CompanyForm = (props: any) => {
  const { selected, saving, submit } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    if (selected) {
      form.setFieldsValue(encodeJsonAttr(selected, ["info"]));
    } else {
      form.setFieldsValue({});
    }
  }, [selected, form]);

  return (
    <Form form={form} layout="vertical" onFinish={submit}>
      <Form.Item label="" name="id" hidden={true}>
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item
        required
        label="Name"
        name="name"
        tooltip="Input name"
        rules={[
          {
            required: true,
            message: "Input name",
          },
        ]}
      >
        <Input placeholder="Input name" disabled={saving} />
      </Form.Item>
      {selected === undefined && (
        <Form.Item
          label="User email"
          required
          name="userEmail"
          tooltip="User email"
          rules={[
            {
              required: true,
              message: "Input user email",
            },
          ]}
        >
          <Input placeholder="Input user email" disabled={saving} />
        </Form.Item>
      )}
      <Form.Item label="Address" name="address" tooltip="Input address">
        <Input.TextArea placeholder="Input address" disabled={saving} />
      </Form.Item>
      <JsonField />
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={saving}>
          Хадгалах
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyForm;
