import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  listTenants,
  searchClient,
  searchClientRole,
  searchRole,
  searchUser,
  userRole,
  listSites,
} from "../api";

export const useClients = (props: any) => {
  const { token, last } = props;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchList = async (token: string) => {
      setLoading(true);
      try {
        const result = await searchClient({ token });
        if (result?.error) {
          message.error(<div>{result.error.message}</div>);
        } else {
          setList(result.list || []);
        }
      } catch (err: any) {
        message.error(<div>{err.message}</div>);
      } finally {
        setLoading(false);
      }
    };

    fetchList(token);
  }, [token, last]);

  return { loading, list };
};

export const useRealmRoles = (props: any) => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  //   const { token } = useContext(KeycloakContext);
  const { token, last } = props;
  useEffect(() => {
    const fetchRoles = async (token: string) => {
      setLoading(true);
      setRoles([]);
      try {
        const result = await searchRole({ token });
        if (result?.error) {
          // has error
          message.error(<div>{result.error.message}</div>);
        } else {
          setRoles(result.list || []);
        }
      } catch (err: any) {
        message.error(<div>{err.message}</div>);
      } finally {
        setLoading(false);
      }
    };
    if (token) {
      fetchRoles(token);
    }
  }, [token, last]);

  return { loading, roles };
};

export const useClientRoles = (props: any) => {
  const { token, clientId } = props;
  const [roles, setRoles] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchRoles = async (token: string, clientId: string) => {
      setLoading(true);
      setRoles([]);
      try {
        const result = await searchClientRole({ id: clientId, token });
        if (result?.error) {
          // has error
          message.error(<div>{result.error.message}</div>);
        } else {
          setRoles(result.list || []);
        }
      } catch (err: any) {
        message.error(<div>{err.message}</div>);
      } finally {
        setLoading(false);
      }
    };
    if (clientId) {
      fetchRoles(token, clientId);
    } else {
      setRoles([]);
    }
  }, [token, clientId]);
  return { loading, roles };
};

export const useUserClientRoles = (props: any) => {
  const { token, id, clientId, last } = props;
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRoles = async (id: string, clientId: string, token: string) => {
      setLoading(true);
      setRoles([]);
      try {
        const result = await userRole({ id, clientId, token });
        if (result?.error) {
          // has error
          message.error(<div>{result.error.message}</div>);
        } else {
          setRoles(result.list || []);
        }
      } catch (err: any) {
        message.error(<div>{err.message}</div>);
      } finally {
        setLoading(false);
      }
    };
    if (id && clientId) {
      fetchRoles(id, clientId, token);
    }
  }, [id, clientId, token, last]);
  return { loading, roles };
};

export const useUserRealmRoles = (props: any) => {
  const { token, id, last } = props;
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRoles = async (id: string, token: string) => {
      setLoading(true);
      setRoles([]);
      try {
        const result = await userRole({ id, token });
        if (result?.error) {
          // has error
          message.error(<div>{result.error.message}</div>);
        } else {
          setRoles(result.list || []);
        }
      } catch (err: any) {
        message.error(<div>{err.message}</div>);
      } finally {
        setLoading(false);
      }
    };
    fetchRoles(id, token);
  }, [id, token, last]);
  return { loading, roles };
};

export const useUser = (props: any) => {
  const { token, state } = props;
  const [list, updateList] = useState<any[]>([]);
  const [loading, updateLoading] = useState(false);

  useEffect(() => {
    const fetchList = async (token: string, state: any) => {
      const { page, max, search, tenant } = state;
      updateLoading(true);
      try {
        const result = await searchUser({ page, max, search, token, tenant });
        if (result?.error) {
          // has error
          message.error(<div>{result.error.message}</div>);
        } else {
          updateList(result.list || []);
          // updateTotal(result.total || 0)
        }
      } catch (err: any) {
        message.error(<div>{err.message}</div>);
      } finally {
        updateLoading(false);
      }
    };

    fetchList(token, state);

    // console.log('called at', new Date())
  }, [state, token]);

  const dispatch = useCallback((props: any) => {
    if (props.type === "merge") {
      updateList((list: any[]) =>
        list.map((o: any) => {
          return o.id === props?.value.id ? { ...o, ...props?.value } : o;
        })
      );
    } else if (props.type === "delete") {
      updateList((list: any[]) =>
        list.filter((o: any) => {
          return o.id !== props?.value;
        })
      );
    }
  }, []);
  return { loading, list, dispatch };
};

export const useTenant = (props: any) => {
  const { token, last } = props;
  const [loading, updateLoading] = useState(false);
  const [list, updateList] = useState<string[]>([]);

  useEffect(() => {
    const fetchList = async (token: string) => {
      updateLoading(true);
      try {
        const _result = await listTenants(token);
        if (_result.error) {
          message.error(<div>{_result.error.message}</div>);
        } else {
          updateList(_result.list || []);
        }
      } catch (err: any) {
        message.error(<div>{err.message}</div>);
      } finally {
        updateLoading(false);
      }
    };
    if (token) {
      fetchList(token);
    }
  }, [token, last]);

  return { loading, list };
};

export const useSites = (props: any) => {
  const { token } = props;
  const [loading, updateLoading] = useState(false);
  const [list, updateList] = useState<string[]>([]);

  useEffect(() => {
    const fetchList = async (token: string) => {
      updateLoading(true);
      try {
        const _result = await listSites(token);
        if (_result.error) {
          message.error(<div>{_result.error.message}</div>);
        } else {
          updateList(_result.list || []);
        }
      } catch (err: any) {
        message.error(<div>{err.message}</div>);
      } finally {
        updateLoading(false);
      }
    };
    if (token) {
      fetchList(token);
    }
  }, [token]);

  return { loading, list };
};
