import { Fragment, useCallback, useContext, useState } from "react";
import { KeycloakContext } from "../KeycloakProvider";
import {
  Button,
  Modal,
  Space,
  Tree,
  message,
  Input,
  Spin,
  Popconfirm,
  Divider,
  Drawer,
  Table,
  Typography,
} from "antd";
import { createGroup, deleteGroup, removeFromGroup } from "../api";
// import { Link } from "react-router-dom";
import { useGroup, useGroupMember } from "../components/hooks/Group";
import { DownOutlined } from "@ant-design/icons";
// import { TreeProps } from "rc-tree";
import { DataNode } from "rc-tree/lib/interface";
import JoinGroup from "../components/modals/JoinGroup";
const { Title } = Typography;

const GroupElement = (props: any) => {
  const { openForm, onDelete, openMember } = props;
  return (
    <div className="page-body">
      <div className="page-body-header flex">
        <Space align="start" size="large">
          <div>{props.name}</div>
          <Fragment>
            {/* <Link to={`/group/${props.id}/member`}>Members</Link> */}
            {openForm && (
              <Button
                type="primary"
                onClick={() => openForm(props.id)}
                size="small"
              >
                Add child
              </Button>
            )}

            {onDelete && (
              <Popconfirm
                placement="top"
                title={`Delete "${props.name}" ?`}
                onConfirm={() => onDelete(props.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger size="small">
                  Delete
                </Button>
              </Popconfirm>
            )}

            {openMember && (
              <Button
                onClick={() => openMember(props.id, props.name)}
                size="small"
              >
                Member
              </Button>
            )}
          </Fragment>
        </Space>
      </div>
    </div>
  );
};

const convertList = (list: any[], actions: any): DataNode[] => {
  return list.map((l: any) => {
    return convert(l, actions);
  });
};

const convert = (l: any, actions: any) => {
  return {
    title: <GroupElement {...l} {...actions} />,
    key: l.id,
    children: convertList(l.subGroups || [], actions),
  };
};

const columns = (actions: any) => [
  {
    title: "username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "action",
    key: "action",
    render: (text: any, record: any) => {
      return (
        <Fragment>
          {actions?.removeMember && (
            <Popconfirm
              placement="top"
              title={`Remove "${record.username}" from "${actions.groupName}" ?`}
              onConfirm={() =>
                actions?.removeMember(actions.groupId, record.id)
              }
              okText="Yes"
              cancelText="No"
            >
              <Button danger size="small">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Fragment>
      );
    },
  },
];

const Group = (props: any) => {
  const { token } = useContext(KeycloakContext);
  const [last, setLast] = useState(0);
  const { loading, list, root } = useGroup({ token, last });

  // const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
  //   console.log("selected", selectedKeys, info);
  // };

  const [name, setName] = useState<string>("");
  const [show, updateShow] = useState(false);
  const [parentId, updateParentId] = useState<string | undefined>();
  const addNewGroup = () => {
    updateParentId(root);
    updateShow(true);
  };
  const openForm = (parentId: string) => {
    updateParentId(parentId);
    updateShow(true);
  };
  const closeForm = () => {
    updateShow(false);
    updateParentId(undefined);
    setName("");
  };

  const addGroup = useCallback(async () => {
    if (!name) {
      message.warning("Input group name!");
      return;
    }

    try {
      const result = await createGroup({ id: parentId, name, token });

      if (result?.success) {
        closeForm();
        setLast(new Date().getTime());
      } else {
        message.error(<div>{result?.error?.message || "Error occured"}</div>);
      }
    } catch (err: any) {
      console.log("err", err);
      message.error(<div>{err?.message || "Error occured"}</div>);
    }
  }, [parentId, name, token]);

  const handleName = useCallback((e: any) => {
    console.log("handleName", e.target.value);
    setName(e.target.value);
  }, []);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        const result = await deleteGroup({ id, token });

        if (result?.success) {
          setLast(new Date().getTime());
        } else {
          message.error(<div>{result?.error?.message || "Error occured"}</div>);
        }
      } catch (err: any) {
        console.log("err", err);
        message.error(<div>{err?.message || "Error occured"}</div>);
      }
    },
    [token]
  );

  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState<any>();
  const [showMember, setShowMember] = useState(false);
  const openMember = (groupId: string, groupName?: string) => {
    setGroupId(groupId);
    setGroupName(groupName);
    setShowMember(true);
  };
  const closeMember = () => {
    setShowMember(false);
    setGroupId("");
    setGroupName(undefined);
  };
  const [lastMember, setLastMember] = useState(0);
  const { loading: loadMember, list: members } = useGroupMember({
    token,
    id: groupId,
    last: lastMember,
  });

  const removeMember = async (groupId: string, memberId: string) => {
    try {
      const result = await removeFromGroup({ groupId, memberId, token });

      if (result?.success) {
        setLastMember(new Date().getTime());
      } else {
        message.error(<div>{result?.error?.message || "Error occured"}</div>);
      }
    } catch (err: any) {
      console.log("err", err);
      message.error(<div>{err?.message || "Error occured"}</div>);
    }
  };

  const [showUser, setShowUser] = useState(false);
  const closeUser = () => {
    setShowUser(false);
  };

  const searchUser = () => {
    setShowUser(true);
  };

  return (
    <Fragment>
      <div className="page-header">
        <p className="header-label">
          <Title level={2}>Group tree</Title>
        </p>
      </div>
      <div className="page-body">
        <div className="page-body-header flex">
          <Space align="start" size="large" />
          {root && (
            <Button
              type="primary"
              // style={{ float: "left" }}
              onClick={addNewGroup}
            >
              Add Group
            </Button>
          )}
        </div>
        <Divider />
        {loading ? (
          <Spin size="large" />
        ) : (
          <Tree
            showLine
            defaultExpandAll={true}
            switcherIcon={<DownOutlined />}
            // onSelect={onSelect}
            treeData={convertList(list, { openForm, onDelete, openMember })}
          />
        )}
      </div>
      <Modal
        title="Add child group"
        open={show}
        onOk={addGroup}
        onCancel={closeForm}
      >
        <p>
          <Input
            onChange={handleName}
            value={name}
            placeholder="input group name"
          />
        </p>
      </Modal>
      <Drawer
        width={600}
        placement="right"
        closable={true}
        onClose={closeMember}
        open={showMember}
      >
        <p
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          <Title level={3}>
            Group members - <strong>{groupName}</strong>
          </Title>
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={searchUser}
          >
            Add To Group
          </Button>
        </p>

        <Divider />
        <Table
          rowKey={"id"}
          loading={loadMember}
          dataSource={members}
          columns={columns({ groupId, groupName, removeMember })}
          pagination={false}
        />

        <JoinGroup
          group={{ id: groupId, name: groupName }}
          show={showUser}
          close={closeUser}
          selected={members.map((m: any) => m.id)}
          dispatch={() => setLastMember(new Date().getTime())}
        />
      </Drawer>
    </Fragment>
  );
};

export default Group;
