import { Fragment, useCallback, useContext, useReducer, useState } from "react";
import { KeycloakContext } from "../KeycloakProvider";
import { useCar, useCarDispatch } from "../components/hooks/Car";
import {
  Button,
  Divider,
  Drawer,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import { Action, Pagination } from "../types";
import CarForm from "../components/forms/CarForm";
import Query from "../components/common/Query";
import SiteFilter from "../components/common/SiteFilter";
import { useSource, useSourceDispatch } from "../components/hooks/Source";
import SourceForm from "../components/forms/SourceForm";
const { Title } = Typography;
const columns = (props: {
  pagination?: Pagination;
  actions?: Action;
  sources?: any[];
}) => [
  {
    title: "name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "plate",
    dataIndex: "plate",
    key: "plate",
  },
  {
    title: "site",
    dataIndex: "site",
    key: "site",
  },
  {
    title: "info",
    dataIndex: "info",
    key: "info",
    render: (text: any, record: any) => {
      return JSON.stringify(record.info);
    },
  },
  {
    title: "source",
    key: "source",
    render: (text: any, record: any) => {
      const exists =
        props.sources?.filter(
          (s: any) => s.targetType === "vehicle" && s.target === record.id
        ) || [];

      return (
        <Fragment>
          {exists?.map((s: any, i: number) => {
            return (
              <span key={i}>
                {s.src}/{s.srcType}
              </span>
            );
          })}
        </Fragment>
      );
    },
  },
  {
    title: "action",
    key: "action",
    render: (text: any, record: any) => {
      return (
        <Fragment>
          {props.actions?.openEdit && (
            <Button
              onClick={() =>
                props.actions?.openEdit({ type: "edit", value: record })
              }
              size="small"
            >
              Edit
            </Button>
          )}
          {props.actions?.onDelete && (
            <Popconfirm
              placement="top"
              title={`Delete "${record.name}" ?`}
              onConfirm={() => props.actions?.onDelete(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger size="small">
                Delete
              </Button>
            </Popconfirm>
          )}
          {props.actions?.openEdit && (
            <Button
              onClick={() =>
                props.actions?.openSource({
                  value: {
                    ...record,
                    targetType: "vehicle",
                  },
                })
              }
              size="small"
            >
              Add source
            </Button>
          )}
        </Fragment>
      );
    },
  },
];
const Car = (props: any) => {
  const { token, srcTypes } = useContext(KeycloakContext);
  const [state, updateState] = useReducer(
    (state: any, action: any) => {
      switch (action.type) {
        default:
          return { ...state, ...action };
      }
    },
    {
      page: 1,
      max: 10,
      search: "",
    }
  );
  const { loading, list, dispatch: dispatchQuery } = useCar({ token, state });

  const [lastSource, updateLastSource] = useState(0);
  const { list: sources, dispatch: dispatchSourceQuery } = useSource({
    token,
    targetType: "vehicle",
    last: lastSource,
  });

  const dispatch = useCallback(
    (action: any) => {
      if (action.type === "load") {
        updateState({ last: new Date().getTime(), page: 1 });
      } else if (action.type === "merge") {
        //   updateState({ last: new Date().getTime() }); //force reload
        dispatchQuery(action);
      } else if (action.type === "delete") {
        dispatchQuery(action);
      }
    },
    [dispatchQuery]
  );

  const {
    openEdit,
    closeEdit,
    onSubmit,
    onDelete,
    state: dispatchState,
  } = useCarDispatch({ token, dispatch });

  const dispatchSource = useCallback(
    (action: any) => {
      if (action.type === "load") {
        updateLastSource(new Date().getTime());
      }
    },
    [dispatchSourceQuery]
  );

  const {
    openEdit: sourceEdit,
    closeEdit: sourceClose,
    onSubmit: sourceSubmit,
    state: sourceState,
  } = useSourceDispatch({ token, dispatch: dispatchSource });

  return (
    <Fragment>
      <div className="page-header">
        <p className="header-label">
          <Title level={2}>Vehicle list</Title>
        </p>
      </div>
      <div className="page-body">
        <div className="page-body-header flex">
          <Space align="start" size="large">
            <SiteFilter dispatch={updateState} />
            <Query dispatch={updateState} />
          </Space>
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => openEdit({ type: "add" })}
          >
            Add Vehicle
          </Button>
        </div>
        <Table
          rowKey={"id"}
          loading={loading}
          dataSource={list}
          columns={columns({
            actions: {
              openEdit,
              onDelete,
              openSource: sourceEdit,
            },
            sources: sources,
          })}
          pagination={false}
          // pagination={{
          //     current: state?.page,
          //     total: total,
          //     pageSize: state?.per_page,
          //     showTotal: (total, range) => `Нийт ${total}`,
          //     onChange,
          //     onShowSizeChange,
          // }}
        />
      </div>
      <Drawer
        width={600}
        placement="right"
        closable={true}
        onClose={closeEdit}
        open={dispatchState.show}
      >
        <p
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          <Title level={3}>
            Vehicle {dispatchState.selected ? "edit" : "add"}
          </Title>
        </p>
        <Divider />
        {dispatchState.show && <CarForm {...dispatchState} submit={onSubmit} />}
      </Drawer>
      <Drawer
        width={600}
        placement="right"
        closable={true}
        onClose={sourceClose}
        open={sourceState.show}
      >
        <p
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          <Title level={3}>Bind source to Vehicle</Title>
        </p>
        <Divider />
        {sourceState.show && (
          <SourceForm {...sourceState} submit={sourceSubmit} />
        )}
      </Drawer>
    </Fragment>
  );
};

export default Car;
