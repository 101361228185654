import { Fragment, useContext, useState } from "react";
import { KeycloakContext } from "../KeycloakProvider";
import { Divider } from "antd";
import ClientFilter from "./common/ClientFilter";
import { useClientRoles, useUserClientRoles, useUserRealmRoles } from "./Hooks";
import RoleChanger from "./common/RoleChanger";

const UserRole = (props: any) => {
  const { token } = useContext(KeycloakContext);
  const { userId, allRoles, updateSaving, saving } = props;

  const [clientId, setClientId] = useState<string>("");
  const changeClient = (value: string) => {
    setClientId(value);
  };
  const { roles: allClientRoles } = useClientRoles({
    clientId,
    token,
  });

  const [last, updateLast] = useState<number>(0);
  const [clientLast, updateClientLast] = useState<number>(0);

  const { roles: realmRoles } = useUserRealmRoles({ id: userId, token, last });

  const { roles: clientRoles } = useUserClientRoles({
    id: userId,
    clientId,
    token,
    last: clientLast,
  });

  if (!userId) {
    return null;
  }

  return (
    <Fragment>
      {allRoles && (
        <div>
          <p
            className="site-description-item-profile-p"
            style={{ marginBottom: 24 }}
          >
            Realm Roles
          </p>
          <RoleChanger
            token={token}
            saving={saving}
            updateSaving={updateSaving}
            allRoles={allRoles}
            roles={realmRoles}
            userId={userId}
            updateLast={updateLast}
          />
        </div>
      )}
      <Divider />
      <div>
        <p
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          Client Roles
        </p>
        <ClientFilter onChange={changeClient} />
        {allClientRoles && (
          <RoleChanger
            token={token}
            saving={saving}
            updateSaving={updateSaving}
            allRoles={allClientRoles}
            roles={clientRoles}
            userId={userId}
            clientId={clientId}
            updateLast={updateClientLast}
          />
        )}
      </div>
    </Fragment>
  );
};

export default UserRole;
