import Search from "antd/es/input/Search";

const Query = (props: any) => {
  const { dispatch } = props;
  const handleSearch = (val: string) => {
    dispatch({ page: 1, search: val });
  };

  return (
    <Search
      allowClear
      placeholder="Хайх үгээ оруулна уу..."
      onSearch={handleSearch}
      enterButton
    />
  );
};

export default Query;
