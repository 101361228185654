import { Button, Form, Input, Select } from "antd";
import { useContext, useEffect } from "react";
import { KeycloakContext } from "../../KeycloakProvider";
import { APP_ADMIN } from "../../constants";
import { getUserByName } from "../../api";

const UserForm = (props: any) => {
  const { hasRealmRole, tenants, token } = useContext(KeycloakContext);
  const { selected, saving, save } = props;
  const [form] = Form.useForm();

  //   useEffect(() => {
  //     return () => {
  //       form.resetFields();
  //     };
  //   }, []);

  useEffect(() => {
    if (selected) {
      form.setFieldsValue(selected);
    } else {
      form.setFieldsValue({});
    }
  }, [selected, form]);

  const checkUserName = async (action: any, value: any) => {
    // console.log("action", action);
    // console.log("value", value);
    try {
      if (value === "" || value === undefined) {
        return Promise.resolve();
      }
      const user = await getUserByName({ username: value, token });
      if (user) {
        return Promise.reject("username exist!");
      }
      return Promise.resolve();
    } catch (err: any) {
      console.log("checkUserName", err);
      return Promise.resolve();
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={save}>
      <Form.Item label="" name="id" hidden={true}>
        <Input disabled={saving} />
      </Form.Item>

      <Form.Item
        label="username"
        required
        name="username"
        tooltip="Хэрэглэгчийн нэр оруулна уу"
        rules={[
          {
            required: true,
            message: "Хэрэглэгчийн нэр оруулна уу",
          },
          {
            validator: checkUserName,
          },
        ]}
      >
        <Input
          placeholder="Хэрэглэгчийн нэр оруулна уу"
          disabled={saving || selected !== undefined}
        />
      </Form.Item>

      {/* <Form.Item
                        label="Утас"
                        name="phone"
                        tooltip="Утас оруулна уу"
                    >
                        <Input placeholder="Утас оруулна уу" disabled={saving} />
                    </Form.Item> */}
      <Form.Item
        label="Имэйл"
        required
        name="email"
        tooltip="Имэйл хаяг оруулна уу"
        rules={[
          {
            required: true,
            message: "Имэйл хаяг оруулна уу",
          },
        ]}
      >
        <Input placeholder="Имэйл хаяг оруулна уу" disabled={saving} />
      </Form.Item>
      <Form.Item label="Нэр" name="firstName" tooltip="Нэр оруулна уу">
        <Input placeholder="Нэр оруулна уу" disabled={saving} />
      </Form.Item>
      <Form.Item label="Овог" name="lastName" tooltip="Овог оруулна уу">
        <Input placeholder="Нэр оруулна уу" disabled={saving} />
      </Form.Item>
      {selected === undefined && (
        <Form.Item
          label="Нууц үг"
          required
          name="password"
          tooltip="Нууц үг"
          rules={[
            {
              required: true,
              message: "Нууц үг оруулна уу",
            },
          ]}
        >
          <Input.Password placeholder="Нууц үг оруулна уу" disabled={saving} />
        </Form.Item>
      )}
      {hasRealmRole &&
        hasRealmRole(APP_ADMIN) &&
        tenants !== undefined &&
        selected === undefined && (
          <Form.Item
            label="Tenant"
            required
            name="tenant"
            tooltip="Tenant"
            rules={[
              {
                required: true,
                message: "Input tenant",
              },
            ]}
          >
            <Select
              options={tenants.map((t: string) => ({ label: t, value: t }))}
            />
          </Form.Item>
        )}

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={saving}>
          Хадгалах
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
