import { message } from "antd";
import { useCallback, useEffect, useReducer, useState } from "react";
import {
  bindSource,
  searchSource,
  searchSourceType,
  createSourceType,
  updateSourceType,
  deleteSourceType,
} from "../../api";
import { decodeJsonAttr } from "../Utils";

export const useSource = (props: any) => {
  const { token, targetType, last } = props;
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const fetchList = async (
      token: string,
      last?: number,
      targetType?: string
    ) => {
      setLoading(true);
      setList([]);

      try {
        const result = await searchSource({
          token,
          last,
          active: "",
          targetType,
        });

        if (result?.error) {
          message.error(<div>{result.error.message} </div>);
        } else {
          setList(result.list || []);
        }
      } catch (err: any) {
        message.error(<div>{err.message} </div>);
      } finally {
        setLoading(false);
      }
    };

    fetchList(token, last, targetType);
  }, [token, last, targetType]);

  const dispatch = useCallback((action: any) => {
    if (action?.type === "load") {
      // refresh source
    }
  }, []);
  return { loading, list, dispatch };
};

export const useSourceDispatch = (props: any) => {
  const { token, dispatch } = props;
  const [state, updateState] = useReducer(
    (_state: any, _action: any) => {
      return { ..._state, ..._action };
    },
    {
      selected: undefined,
      saving: false,
      error: null,
      show: false,
    }
  );

  // const onDelete = useCallback(
  //   async (action: any) => {
  //     try {
  //       const id = action.id;

  //       const result = await deleteSource({ id, token });

  //       if (result?.success) {
  //         if (dispatch) {
  //           dispatch({ type: "delete", value: id });
  //         }
  //       } else {
  //         message.error(<div>{result?.error?.message || "Error occured"}</div>);
  //       }
  //     } catch (err: any) {
  //       console.log("err", err);
  //       message.error(<div>{err?.message || "Error occured"}</div>);
  //     }
  //   },
  //   [token, dispatch]
  // );

  const onSubmit = useCallback(
    async (action: any) => {
      console.log("values", action);

      updateState({ saving: true, error: null });

      try {
        if (action.id === undefined) {
          const result = await bindSource({ ...action, token });
          if (result?.id) {
            // success

            updateState({ show: false, selected: undefined });
            if (dispatch) {
              dispatch({ type: "load" });
            }
          } else {
            message.error(<div>{result?.error?.message || "Алдаа гарав"}</div>);
            updateState({ error: result?.error });
          }
        }
        // else {
        //   const result = await updateSource({ ...action, token });

        //   if (result?.success) {
        //     // success
        //     updateState({ show: false, selected: undefined });
        //     if (dispatch) {
        //       dispatch({
        //         type: "merge",
        //         value: decodeJsonAttr(action, ["info"]),
        //       });
        //     }
        //   } else {
        //     message.error(<div>{result?.error?.message || "Алдаа гарав"}</div>);
        //     updateState({ error: result?.error });
        //   }
        // }
      } catch (err: any) {
        console.log("err", err);
        message.error(<div>{err?.message || "Алдаа гарав"}</div>);
        updateState({ error: err });
      } finally {
        updateState({ saving: false });
      }
    },
    [token, dispatch]
  );

  const openEdit = useCallback((action: any) => {
    updateState({ selected: action?.value, show: true });
  }, []);
  const closeEdit = useCallback((action: any) => {
    updateState({ show: false, selected: undefined });
  }, []);

  // return { openEdit, closeEdit, onSubmit, onDelete, state };
  return { openEdit, closeEdit, onSubmit, state };
};

export const useSourceType = (props: any) => {
  const { token, state, last } = props;
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const fetchList = async (token: string, state: any) => {
      setLoading(true);
      setList([]);

      try {
        const result = await searchSourceType({ token, ...state });

        if (result?.error) {
          message.error(<div>{result.error.message} </div>);
        } else {
          setList(result.list || []);
        }
      } catch (err: any) {
        message.error(<div>{err.message} </div>);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchList(token, state);
    }
  }, [token, last, state]);

  const dispatch = useCallback((action: any) => {
    if (action?.type === "merge") {
      setList((list: any[]) =>
        list.map((o: any) => {
          return o.id === action?.value.id ? { ...o, ...action?.value } : o;
        })
      );
    } else if (action.type === "delete") {
      setList((list: any[]) =>
        list.filter((o: any) => {
          return o.id !== action?.value;
        })
      );
    }
  }, []);
  return { loading, list, dispatch };
};

export const useSourceTypeDispatch = (props: any) => {
  const { token, dispatch } = props;
  const [state, updateState] = useReducer(
    (_state: any, _action: any) => {
      return { ..._state, ..._action };
    },
    {
      selected: undefined,
      saving: false,
      error: null,
      show: false,
    }
  );

  const onDelete = useCallback(
    async (action: any) => {
      try {
        const id = action.id;

        const result = await deleteSourceType({ id, token });

        if (result?.success) {
          if (dispatch) {
            dispatch({ type: "delete", value: id });
          }
        } else {
          message.error(<div>{result?.error?.message || "Error occured"}</div>);
        }
      } catch (err: any) {
        console.log("err", err);
        message.error(<div>{err?.message || "Error occured"}</div>);
      }
    },
    [token, dispatch]
  );

  const onSubmit = useCallback(
    async (action: any) => {
      console.log("values", action);

      updateState({ saving: true, error: null });

      try {
        if (action.id === undefined) {
          const result = await createSourceType({ ...action, token });
          if (result?.id) {
            // success

            updateState({ show: false, selected: undefined });
            if (dispatch) {
              dispatch({ type: "load" });
            }
          } else {
            message.error(<div>{result?.error?.message || "Алдаа гарав"}</div>);
            updateState({ error: result?.error });
          }
        } else {
          const result = await updateSourceType({ ...action, token });

          if (result?.success) {
            // success
            updateState({ show: false, selected: undefined });
            if (dispatch) {
              dispatch({
                type: "merge",
                value: decodeJsonAttr(action, ["info"]),
              });
            }
          } else {
            message.error(<div>{result?.error?.message || "Алдаа гарав"}</div>);
            updateState({ error: result?.error });
          }
        }
      } catch (err: any) {
        console.log("err", err);
        message.error(<div>{err?.message || "Алдаа гарав"}</div>);
        updateState({ error: err });
      } finally {
        updateState({ saving: false });
      }
    },
    [token, dispatch]
  );

  const openEdit = useCallback((action: any) => {
    updateState({ selected: action?.value, show: true });
  }, []);
  const closeEdit = useCallback((action: any) => {
    updateState({ show: false, selected: undefined });
  }, []);

  return { openEdit, closeEdit, onSubmit, onDelete, state };
};
