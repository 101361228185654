import { useContext } from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { KeycloakContext } from "./KeycloakProvider";


const ProtectedRoute = (props:any) => {
  const {authenticated} = useContext(KeycloakContext)
  
  if (!authenticated) {
    return <Navigate to={'/'} replace />;
  }

  return <Dashboard>
    {props.children}
  </Dashboard>
}


export default ProtectedRoute