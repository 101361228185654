import { Fragment, useContext } from "react";
import { KeycloakContext } from "../../KeycloakProvider";
import { Select } from "antd";

const SiteFilter = (props: any) => {
  const { sites } = useContext(KeycloakContext);
  const { dispatch } = props;
  const onClear = () => {
    dispatch({ page: 1, site: undefined });
  };
  const onChange = (value: string) => {
    dispatch({ page: 1, site: value });
  };
  return (
    <Fragment>
      {sites !== undefined && (
        <Select
          allowClear
          placeholder="select site"
          onClear={onClear}
          style={{ width: "200px" }}
          options={sites.map((t: any) => ({ label: t.name, value: t.name }))}
          onChange={onChange}
        />
      )}
    </Fragment>
  );
};

export default SiteFilter;
