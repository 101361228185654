import { Select } from "antd";
import { useContext } from "react";
import { KeycloakContext } from "../../KeycloakProvider";
import { useClients } from "../Hooks";

const ClientFilter = (props: any) => {
  const { token } = useContext(KeycloakContext);
  const { onChange, filtered } = props;

  const { loading, list } = useClients({ token });

  const clear = () => {
    if (onChange) {
      onChange();
    }
  };
  const change = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  const filterFunc = (input: string, option: any): boolean => {
    return (option?.label ?? "").includes(input.toLowerCase());
  };

  if (loading || list.length === 0) {
    return null;
  }

  return (
    <Select
      allowClear
      placeholder="select client"
      onClear={clear}
      style={{ width: "200px" }}
      options={list.map((c: any) => ({ label: c.clientId, value: c.id }))}
      onChange={change}
      filterOption={filtered !== undefined ? filterFunc : undefined}
    />
  );
};

export default ClientFilter;
