import { Button, Form, Input, Select } from "antd";
import { useContext, useEffect } from "react";
import { KeycloakContext } from "../../KeycloakProvider";
import { APP_ADMIN } from "../../constants";
import JsonField from "./JsonField";
import { encodeJsonAttr } from "../Utils";

const CarForm = (props: any) => {
  const { hasRealmRole, tenants, sites } = useContext(KeycloakContext);

  const { selected, saving, submit } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    if (selected) {
      // form.setFieldsValue(selected);
      form.setFieldsValue(encodeJsonAttr(selected, ["info"]));
      // form.setFieldsValue({ ...selected, info: JSON.stringify(selected.info) });
    } else {
      form.setFieldsValue({});
    }
  }, [selected, form]);

  return (
    <Form form={form} layout="vertical" onFinish={submit}>
      <Form.Item label="" name="id" hidden={true}>
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item label="Name" name="name" tooltip="Input name">
        <Input placeholder="Input name" disabled={saving} />
      </Form.Item>
      <Form.Item label="Plate" name="plate" tooltip="Input plate">
        <Input placeholder="Input plate" disabled={saving} />
      </Form.Item>
      {sites !== undefined && (
        <Form.Item label="Site" name="site" tooltip="site">
          <Select
            options={sites.map((t: any) => ({ label: t.name, value: t.name }))}
            disabled={saving}
          />
        </Form.Item>
      )}
      <JsonField />
      {hasRealmRole &&
        hasRealmRole(APP_ADMIN) &&
        tenants !== undefined &&
        selected === undefined && (
          <Form.Item
            label="Tenant"
            required
            name="tenant"
            tooltip="Tenant"
            rules={[
              {
                required: true,
                message: "Input tenant",
              },
            ]}
          >
            <Select
              options={tenants.map((t: string) => ({ label: t, value: t }))}
              disabled={saving}
            />
          </Form.Item>
        )}
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={saving}>
          Хадгалах
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CarForm;
