import { Button, Dropdown, Space } from "antd";
import { useContext } from "react";
import { KeycloakContext } from "../KeycloakProvider";

import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Profile = (props: any) => {
  const { token, logout, login, user } = useContext(KeycloakContext);
  const navigate = useNavigate();
  const onClick = ({ key }: { key: any }) => {
    if (key === "logout") {
      logout && logout();
    } else if (key === "token") {
      navigate("/token");
    }
  };

  return (
    <div
      style={{
        float: "right",
        marginRight: "20px",
      }}
    >
      {token ? (
        <Dropdown
          menu={{
            items: [
              {
                label: "Logout",
                key: "logout",
              },
              {
                label: "Token",
                key: "token",
              },
            ],
            onClick,
          }}
        >
          <Button onClick={(e) => e.preventDefault()}>
            <Space>
              {user?.preferred_username}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ) : (
        <div>
          <Button onClick={login}>Login</Button>
        </div>
      )}
    </div>
  );
};

export default Profile;
