import { Form, Input } from "antd";

const JsonField = (props: any) => {
  const checkJson = (action: any, value: any) => {
    // console.log("action", action);
    // console.log("value", value);
    try {
      if (value === "" || value === undefined) {
        return Promise.resolve();
      }
      const json = JSON.parse(value);
      return Promise.resolve(json);
    } catch (err: any) {
      console.log("json err", err);
      return Promise.reject("invalid json");
    }
  };
  return (
    <Form.Item
      label="Info"
      name="info"
      tooltip="Input info"
      rules={[{ validator: checkJson }]}
    >
      <Input.TextArea rows={10} />
    </Form.Item>
  );
};

export default JsonField;
