import { Layout, Menu, Spin } from "antd";

import {
  CarOutlined,
  UserOutlined,
  VideoCameraOutlined,
  DashboardOutlined,
  FileProtectOutlined,
  TeamOutlined,
  WifiOutlined,
  BankOutlined,
  AppstoreOutlined,
  GatewayOutlined,
  ControlOutlined,
  FontColorsOutlined,
  TagsOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { KeycloakContext } from "../KeycloakProvider";
import { APP_ADMIN, RESOURCE, TENANT_ADMIN } from "../constants";
const { Sider } = Layout;

const MENUS = [
  {
    key: "/",
    icon: <DashboardOutlined />,
    label: "Home",
  },

  {
    key: "/group",
    icon: <TeamOutlined />,
    label: "Group",
  },
  {
    key: "/user",
    icon: <UserOutlined />,
    label: "User",
  },
  {
    key: "/asset",
    icon: <FontColorsOutlined />,
    label: "Asset",
  },
  {
    key: "/camera",
    icon: <VideoCameraOutlined />,
    label: "Camera",
  },
  {
    key: "/gateway",
    icon: <GatewayOutlined />,
    label: "Gateway",
  },

  {
    key: "/sensor",
    icon: <WifiOutlined />,
    label: "Sensor",
  },
  {
    key: "/tag",
    icon: <TagsOutlined />,
    label: "Tag",
  },
  {
    key: "/vehicle",
    icon: <CarOutlined />,
    label: "Vehicle",
  },
  {
    key: "/worker",
    icon: <IdcardOutlined />,
    label: "Worker",
  },
  {
    key: "/admin/client",
    icon: <AppstoreOutlined />,
    label: "Client",
  },
  {
    key: "/admin/company",
    icon: <BankOutlined />,
    label: "Company",
  },
  {
    key: "/admin/role",
    icon: <FileProtectOutlined />,
    label: "Role",
  },

  {
    key: "/admin/source-type",
    icon: <ControlOutlined />,
    label: "Source type",
  },
];

const Left = (props: any) => {
  const { hasRealmRole, hasClientRole } = useContext(KeycloakContext);

  const navigate = useNavigate();
  const location = useLocation();

  const onClick = ({ key, keyPath }: { key: string; keyPath: string[] }) => {
    console.log("key", key);
    console.log("keyPath", keyPath);
    navigate(key);
  };

  const [key, setKey] = useState("/user");

  useEffect(() => {
    console.log("location.pathname", location.pathname);
    setKey(location.pathname);
  }, [location.pathname]);

  if (hasRealmRole === undefined || hasClientRole === undefined) {
    return (
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={props.collapsed}
      style={{
        height: "100vh",
      }}
    >
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[key]}
        // items={MENUS}
        onClick={onClick}
      >
        {MENUS.map((item, i) => {
          if (
            (!item.key.startsWith("/admin") &&
              hasClientRole(TENANT_ADMIN, RESOURCE)) ||
            hasRealmRole(APP_ADMIN)
          ) {
            return (
              <Menu.Item key={item.key} icon={item.icon}>
                {item.label}
              </Menu.Item>
            );
          } else {
            return null;
          }
        })}
      </Menu>
    </Sider>
  );
};
export default Left;
