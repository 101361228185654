import { Fragment, useContext, useState } from "react";
import { KeycloakContext } from "../../KeycloakProvider";
import { useUser } from "../Hooks";
import { Button, Modal, Popconfirm, Table, message } from "antd";
import { addToGroup } from "../../api";
import TenantFilter from "../common/TenantFilter";

const columns = (actions: any) => [
  {
    title: "username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "firstName",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "lastName",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "action",
    key: "action",
    render: (text: any, record: any) => {
      return (
        <Fragment>
          <Popconfirm
            placement="top"
            title={`Add "${record.username}" to "${actions.groupName}" ?`}
            onConfirm={() => actions?.onAdd(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" size="small">
              Add
            </Button>
          </Popconfirm>
        </Fragment>
      );
    },
  },
];

const filterUser = (list: any[], selected: any[]) => {
  return list.filter((m: any) => !selected.includes(m.id));
};

const JoinGroup = (props: any) => {
  const { token } = useContext(KeycloakContext);

  const { group, show, close, selected, dispatch: dispatchGroup } = props;
  const [state, setState] = useState({});
  const { loading, list, dispatch } = useUser({ token, state });
  const joinGroup = async (memberId: string) => {
    try {
      const result = await addToGroup({ groupId: group.id, memberId, token });

      if (result?.success) {
        dispatch({ type: "delete", value: memberId });
        dispatchGroup();
      } else {
        message.error(<div>{result?.error?.message || "Error occured"}</div>);
      }
    } catch (err: any) {
      console.log("err", err);
      message.error(<div>{err?.message || "Error occured"}</div>);
    }
  };
  return (
    <Modal
      title={`Add member to "${group.name}"`}
      open={show}
      onCancel={close}
      width={1000}
      okButtonProps={{ disabled: true }}
      footer={[
        <Button key="back" onClick={close}>
          Close
        </Button>,
      ]}
    >
      <TenantFilter dispatch={(action: any) => setState(action)} />
      <Table
        rowKey={"id"}
        loading={loading}
        dataSource={filterUser(list, selected)}
        columns={columns({ groupName: group?.name, onAdd: joinGroup })}
        pagination={false}
      />
    </Modal>
  );
};

export default JoinGroup;
