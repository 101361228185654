import { Button, Checkbox } from "antd";
import { useCallback, useState } from "react";
import { addRole, delRole } from "../../api";

const RoleChanger = (props: {
  allRoles: any[];
  roles: any[];
  saving: boolean;
  updateSaving: any;
  userId: string;
  clientId?: string;
  token?: string;
  updateLast: any;
}) => {
  const {
    allRoles,
    roles,
    saving,
    updateSaving,
    userId,
    clientId,
    token,
    updateLast,
  } = props;
  const [add, setAdd] = useState<string[]>([]);
  const [del, setDel] = useState<string[]>([]);
  const onChanged = (e: any) => {
    console.log("e", e);
    if (e.target.checked) {
      if (e.target.defaultChecked) {
        setDel((del: string[]) => del.filter((a) => a !== e.target.value));
      } else {
        setAdd((add: string[]) => [...add, e.target.value]);
      }
    } else {
      if (e.target.defaultChecked) {
        setDel((del: string[]) => [...del, e.target.value]);
      } else {
        setAdd((add: string[]) => add.filter((a) => a !== e.target.value));
      }
    }
  };

  const isExist = useCallback(
    (roleId: string) => {
      const filter = roles.filter((role: any) => role.id === roleId);

      return filter.length > 0;
    },
    [roles]
  );

  const inAdd = (roleId: string) => {
    const filtered = add.filter((a: string) => a === roleId);
    return filtered.length > 0;
  };
  const inDel = (roleId: string) => {
    const filtered = del.filter((a: string) => a === roleId);
    return filtered.length > 0;
  };

  const updateRole = async () => {
    updateSaving(true);
    if (add.length > 0) {
      try {
        const roles = allRoles
          .filter((r: any) => add.includes(r.id))
          .map((r: any) => ({ id: r.id, name: r.name }));
        await addRole({ id: userId, roles, clientId, token });
        setAdd([]);
      } catch (err: any) {}
    }
    if (del.length > 0) {
      try {
        const roles = allRoles
          .filter((r: any) => del.includes(r.id))
          .map((r: any) => ({ id: r.id, name: r.name }));
        await delRole({ id: userId, roles, clientId, token });
        setDel([]);
      } catch (err: any) {}
    }
    updateSaving(false);
    updateLast(new Date().getTime());
  };

  return (
    <div>
      {allRoles.map((role: any, i: number) => {
        return (
          <div key={i}>
            <Checkbox
              defaultChecked={isExist(role.id)}
              value={role.id}
              checked={(isExist(role.id) && !inDel(role.id)) || inAdd(role.id)}
              disabled={saving}
              onChange={onChanged}
            >
              {role.name}
            </Checkbox>
          </div>
        );
      })}

      <Button
        disabled={(add.length === 0 && del.length === 0) || saving}
        onClick={updateRole}
      >
        Save
      </Button>
    </div>
  );
};

export default RoleChanger;
