import { Fragment, useContext } from "react";
import { KeycloakContext } from "../KeycloakProvider";
import { Col, Row } from "antd";
import ReactJson from "react-json-view";
const style: React.CSSProperties = { background: "#0092ff", padding: "8px 0" };

const Tokens = (props: any) => {
  const { user, tokenParsed } = useContext(KeycloakContext);
  return (
    <Fragment>
      <div className="page-header">
        <p className="header-label">My token</p>
      </div>
      <div className="page-body">
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              Id Token
              {user && <ReactJson src={user} theme="monokai" name={false} />}
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              Access Token
              {tokenParsed && (
                <ReactJson src={tokenParsed} name={false} theme="monokai" />
              )}
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" span={12}>
            <div style={style}></div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
export default Tokens;
