import { Fragment, useContext } from "react";
import { KeycloakContext } from "../../KeycloakProvider";
import { Select } from "antd";
import { APP_ADMIN } from "../../constants";

const TenantFilter = (props: any) => {
  const { tenants, hasRealmRole } = useContext(KeycloakContext);
  const { dispatch } = props;
  const onClear = () => {
    dispatch({ page: 1, tenant: undefined });
  };
  const onChange = (value: string) => {
    dispatch({ page: 1, tenant: value });
  };
  return (
    <Fragment>
      {tenants !== undefined && hasRealmRole && hasRealmRole(APP_ADMIN) && (
        <Select
          allowClear
          placeholder="select tenant"
          onClear={onClear}
          style={{ width: "200px" }}
          options={tenants.map((t: string) => ({ label: t, value: t }))}
          onChange={onChange}
        />
      )}
    </Fragment>
  );
};

export default TenantFilter;
