import qs from 'query-string'
import { BASE_API_URL, STORAGE_API_URL } from "./constants"

const customFetch = (url: string, options: any) => {
    return fetch(url, options)
        .then(resp => {
            if (resp.status === 401) {//authentication
                return resp
            } else if (resp.status === 403) {//authorizaton
                return resp
            } else {
                return resp
            }
        })
}

export const listTenants = (token: string) => {
    let url = `${BASE_API_URL}/api/tenant`


    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const searchClient = (params: any) => {
    let url = `${BASE_API_URL}/api/client`
    const { token, ...rest } = params

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }

    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const searchRole = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/roles`


    if (rest) {
        url += `?${qs.stringify(rest)}`
    }

    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const searchGroup = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/group`


    if (rest) {
        url += `?${qs.stringify(rest)}`
    }

    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const searchClientRole = (params: any) => {
    const { id, token, ...rest } = params
    let url = `${BASE_API_URL}/api/client/${id}/roles`


    if (rest) {
        url += `?${qs.stringify(rest)}`
    }

    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const searchUser = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/user/keycloak`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }

    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const deleteUser = (id: string, token?: string) => {
    return customFetch(`${BASE_API_URL}/api/user/keycloak/${id}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
    }).then((res) => res.json())
}

export const createUser = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/user/keycloak`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const updateUser = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/user/keycloak/${params.id}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'PUT',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const userRole = (params: any) => {
    const { id, clientId, token } = params
    return customFetch(`${BASE_API_URL}/api/user/role?id=${id}&clientId=${clientId ?? ''}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const addRole = (params: any) => {
    const { id, clientId, token, roles } = params
    return customFetch(`${BASE_API_URL}/api/user/role`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({ id, clientId, roles }),
    }).then((res) => res.json())
}

export const delRole = (params: any) => {
    const { id, clientId, token, roles } = params
    return customFetch(`${BASE_API_URL}/api/user/role`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify({ id, clientId, roles }),
    }).then((res) => res.json())
}

export const groupMember = (params: any) => {
    const { id, token } = params
    return customFetch(`${BASE_API_URL}/api/group/${id}/member`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const searchCar = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/inventory/vehicles`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }
    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const searchCamera = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/inventory/cameras`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }
    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const searchGateway = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/inventory/gateways`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }
    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const searchDevice = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/inventory/sensors`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }
    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const createCar = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/vehicle`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const updateCar = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/vehicle`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'PUT',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const deleteCar = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/vehicle`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const createDevice = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/sensor`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const updateDevice = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/sensor`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'PUT',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const deleteDevice = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/sensor`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const createCamera = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/camera`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const updateCamera = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/camera`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'PUT',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const deleteCamera = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/camera`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const createGroup = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/group`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const deleteGroup = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/group`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const addToGroup = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/group/member`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const removeFromGroup = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/group/member`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const searchCompany = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/company`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }
    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const createCompany = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/company`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const updateCompany = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/company`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'PUT',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const deleteCompany = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/company`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const getUserByName = (params: any) => {
    const { token, username } = params
    let url = `${BASE_API_URL}/api/user/${username}`

    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const createGateway = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/gateway`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const updateGateway = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/gateway`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'PUT',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const deleteGateway = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/gateway`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const listSites = (token: string) => {
    let url = `${STORAGE_API_URL}/api/sites`


    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const searchSource = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/inventory/sources`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }
    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const bindSource = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/source`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const searchSourceType = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/inventory/source/types`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }
    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const createSourceType = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/source/type`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const updateSourceType = (params: any) => {
    return Promise.resolve({ success: undefined, error: { message: "Not implemented" } })
}

export const deleteSourceType = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/source/type`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const searchAsset = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/inventory/assets`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }
    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const createAsset = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/asset`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const updateAsset = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/asset`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'PUT',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const deleteAsset = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/asset`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const searchTag = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/inventory/tags`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }
    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const createTag = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/tag`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const updateTag = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/tag`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'PUT',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const deleteTag = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/tag`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const searchWorker = (params: any) => {
    const { token, ...rest } = params
    let url = `${BASE_API_URL}/api/inventory/workers`

    if (rest) {
        url += `?${qs.stringify(rest)}`
    }
    return customFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET',
    }).then((res) => res.json())
}

export const createWorker = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/worker`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const updateWorker = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/worker`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'PUT',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}

export const deleteWorker = (params: any) => {
    const { token, ...rest } = params
    return customFetch(`${BASE_API_URL}/api/inventory/worker`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'DELETE',
        body: JSON.stringify(rest),
    }).then((res) => res.json())
}