import { Route, Routes } from "react-router-dom";
import Camera from "./pages/Camera";
import Car from "./pages/Car";
import Tokens from "./pages/Tokens";
import User from "./pages/User";
import Gateway from "./pages/Gateway";
// import Intro from "./Intro";
import ProtectedRoute from "./ProtectedRoute";
import Client from "./admin/Client";
import Role from "./admin/Role";
import ClientRole from "./components/ClientRole";
import Group from "./pages/Group";
import Welcome from "./pages/Welcome";
import { useContext } from "react";
import { KeycloakContext } from "./KeycloakProvider";
import NotFound from "./pages/NotFound";
import { Spin } from "antd";
import { APP_ADMIN, RESOURCE, TENANT_ADMIN } from "./constants";
import GroupMember from "./pages/GroupMember";
import Device from "./pages/Device";
import Company from "./admin/Company";
import SourceType from "./admin/SourceType";
import Asset from "./pages/Asset";
import Tag from "./pages/Tag";
import Worker from "./pages/Worker";

const AppRoutes = (props: any) => {
  const { hasRealmRole, hasClientRole } = useContext(KeycloakContext);

  if (hasRealmRole === undefined || hasClientRole === undefined) {
    return (
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }
  // else {
  //   console.log("hasRealmRole", hasRealmRole("admin"));
  // }
  return (
    <Routes>
      {/* <Route path="/" Component={Intro} /> */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Welcome />
          </ProtectedRoute>
        }
      />
      <Route
        path="/token"
        element={
          <ProtectedRoute>
            <Tokens />
          </ProtectedRoute>
        }
      />
      {(hasRealmRole(APP_ADMIN) || hasClientRole(TENANT_ADMIN, RESOURCE)) && (
        <Route
          path="/user"
          element={
            <ProtectedRoute>
              <User />
            </ProtectedRoute>
          }
        />
      )}
      {(hasRealmRole(APP_ADMIN) || hasClientRole(TENANT_ADMIN, RESOURCE)) && (
        <Route
          path="/group"
          element={
            <ProtectedRoute>
              <Group />
            </ProtectedRoute>
          }
        />
      )}

      {(hasRealmRole(APP_ADMIN) || hasClientRole(TENANT_ADMIN, RESOURCE)) && (
        <Route
          path="/asset"
          element={
            <ProtectedRoute>
              <Asset />
            </ProtectedRoute>
          }
        />
      )}
      {(hasRealmRole(APP_ADMIN) || hasClientRole(TENANT_ADMIN, RESOURCE)) && (
        <Route
          path="/tag"
          element={
            <ProtectedRoute>
              <Tag />
            </ProtectedRoute>
          }
        />
      )}
      {(hasRealmRole(APP_ADMIN) || hasClientRole(TENANT_ADMIN, RESOURCE)) && (
        <Route
          path="/worker"
          element={
            <ProtectedRoute>
              <Worker />
            </ProtectedRoute>
          }
        />
      )}
      {(hasRealmRole(APP_ADMIN) || hasClientRole(TENANT_ADMIN, RESOURCE)) && (
        <Route
          path="/vehicle"
          element={
            <ProtectedRoute>
              <Car />
            </ProtectedRoute>
          }
        />
      )}
      {(hasRealmRole(APP_ADMIN) || hasClientRole(TENANT_ADMIN, RESOURCE)) && (
        <Route
          path="/camera"
          element={
            <ProtectedRoute>
              <Camera />
            </ProtectedRoute>
          }
        />
      )}
      {(hasRealmRole(APP_ADMIN) || hasClientRole(TENANT_ADMIN, RESOURCE)) && (
        <Route
          path="/sensor"
          element={
            <ProtectedRoute>
              <Device />
            </ProtectedRoute>
          }
        />
      )}
      {(hasRealmRole(APP_ADMIN) || hasClientRole(TENANT_ADMIN, RESOURCE)) && (
        <Route
          path="/gateway"
          element={
            <ProtectedRoute>
              <Gateway />
            </ProtectedRoute>
          }
        />
      )}
      {hasRealmRole(APP_ADMIN) && (
        <Route
          path="/admin/client"
          element={
            <ProtectedRoute>
              <Client />
            </ProtectedRoute>
          }
        />
      )}
      {hasRealmRole(APP_ADMIN) && (
        <Route
          path="/admin/company"
          element={
            <ProtectedRoute>
              <Company />
            </ProtectedRoute>
          }
        />
      )}

      {hasRealmRole(APP_ADMIN) && (
        <Route
          path="/admin/role"
          element={
            <ProtectedRoute>
              <Role />
            </ProtectedRoute>
          }
        />
      )}

      {(hasRealmRole(APP_ADMIN) || hasClientRole(TENANT_ADMIN, RESOURCE)) && (
        <Route
          path="/client/:clientId/role"
          element={
            <ProtectedRoute>
              <ClientRole />
            </ProtectedRoute>
          }
        />
      )}
      {hasRealmRole(APP_ADMIN) && (
        <Route
          path="/admin/source-type"
          element={
            <ProtectedRoute>
              <SourceType />
            </ProtectedRoute>
          }
        />
      )}
      <Route
        path="/group/:groupId/member"
        element={
          <ProtectedRoute>
            <GroupMember />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <NotFound />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
