import Keycloak, { KeycloakError } from "keycloak-js";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSites, useTenant } from "./components/Hooks";
import { IAM_CLIENT_ID, IAM_ENDPOINT, IAM_REALM } from "./constants";
import { useSourceType } from "./components/hooks/Source";

interface IKeycloak {
  authenticated: boolean;
  token?: string;
  idToken?: string;
  refreshToken?: string;
  user?: any;
  logout?: () => void;
  login?: () => void;
  tokenParsed?: any;
  hasRealmRole?: (role: string) => boolean;
  hasClientRole?: (role: string, clientId?: string) => boolean;
  tenants?: string[];
  dispatch?: (action: any) => void;
  sites?: string[];
  srcTypes?: string[];
}

export const KeycloakContext = createContext<IKeycloak>({
  authenticated: false,
});

const KeycloakProvider = (props: any) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>();
  const [idToken, setIdToken] = useState<string | undefined>();
  const [refreshToken, setRefreshToken] = useState<string | undefined>();
  const [user, setUser] = useState<any>();

  const [loading, setLoading] = useState<boolean>(true);

  const authRef = useRef<Keycloak | undefined>();

  const [tokenParsed, setTokenParsed] = useState<any>();

  const [last, updateLast] = useState(0);
  const { list } = useTenant({ token, last });
  const { list: sites } = useSites({ token });
  const { list: srcTypes } = useSourceType({ token, last });

  useEffect(() => {
    const setTokens = (auth: boolean) => {
      setAuthenticated(auth);
      setToken(authRef.current?.token);
      setIdToken(authRef.current?.idToken);
      setRefreshToken(authRef.current?.refreshToken);
      setUser(authRef.current?.idTokenParsed);
      setTokenParsed(authRef.current?.tokenParsed);
    };

    const keycloak: Keycloak = new Keycloak({
      url: IAM_ENDPOINT,
      realm: IAM_REALM,
      clientId: IAM_CLIENT_ID,
    });

    keycloak.onAuthSuccess = () => {
      console.log("onAuthSuccess");
      setTokens(false);
    };

    keycloak.onAuthError = (err: KeycloakError) => {
      console.log("onAuthError", err);
      setTokens(false);
    };

    keycloak.onAuthRefreshSuccess = () => {
      console.log("onAuthRefreshSuccess");
      setTokens(true);
    };

    keycloak.onAuthRefreshError = () => {
      console.log("onAuthRefreshError");
      setTokens(false);
    };

    keycloak.onAuthLogout = () => {
      console.log("onAuthLogout");
    };

    keycloak.onTokenExpired = () => {
      console.log("onTokenExpired");

      authRef.current?.updateToken(-1);
    };

    keycloak.onActionUpdate = (status: string) => {
      console.log("onActionUpdate", status);
    };

    keycloak.onReady = (auth: boolean) => {
      console.log("onReady", auth);
      setLoading(false);
    };

    keycloak
      .init({
        onLoad: "login-required",
        // onLoad: 'check-sso'
        // responseMode: 'query'
      })
      .then((auth: boolean) => {
        console.log("init success", auth);
        setTokens(auth);
      })
      .catch((reason: any) => {
        console.log("init err", reason);
      });

    authRef.current = keycloak;
  }, []);

  const logout = useCallback(() => {
    authRef.current?.logout({ redirectUri: `${window.location.href}` });
  }, []);

  const login = useCallback(() => {
    authRef.current?.login();
  }, []);

  const hasRealmRole = useCallback((role: string) => {
    if (authRef.current) {
      return authRef.current.hasRealmRole(role);
    }
    return false;
  }, []);

  const hasClientRole = useCallback((role: string, clientId?: string) => {
    if (authRef.current) {
      return authRef.current.hasResourceRole(role, clientId);
    }
    return false;
  }, []);

  const dispatch = useCallback((action: any) => {
    if (action?.type === "tenant" || action?.type === "srcType") {
      updateLast(new Date().getTime());
    }
  }, []);

  const pack = useMemo(
    () => ({
      authenticated,
      token,
      idToken,
      refreshToken,
      logout,
      login,
      user,
      tokenParsed,
      hasRealmRole,
      hasClientRole,
      tenants: list,
      dispatch,
      sites,
      srcTypes,
    }),
    [
      authenticated,
      token,
      idToken,
      refreshToken,
      logout,
      login,
      user,
      tokenParsed,
      hasRealmRole,
      hasClientRole,
      list,
      dispatch,
      sites,
      srcTypes,
    ]
  );

  return (
    <KeycloakContext.Provider value={pack}>
      {!loading && <>{props.children}</>}
    </KeycloakContext.Provider>
  );
};

export default KeycloakProvider;
