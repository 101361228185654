import { Button, Form, Input, Select } from "antd";
import { useContext, useEffect } from "react";
import { APP_ADMIN } from "../../constants";
import JsonField from "./JsonField";
import { encodeJsonAttr } from "../Utils";
import { KeycloakContext } from "../../KeycloakProvider";

const SourceTypeForm = (props: any) => {
  const { hasRealmRole, tenants, sites } = useContext(KeycloakContext);
  const { selected, saving, submit } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    if (selected) {
      // form.setFieldsValue(encodeJsonAttr(selected, ["info"]));
      form.setFieldsValue(selected);
    } else {
      form.setFieldsValue({});
    }
  }, [selected, form]);

  return (
    <Form form={form} layout="vertical" onFinish={submit}>
      <Form.Item label="" name="id" hidden={true}>
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item
        required
        label="Name"
        name="name"
        tooltip="Input name"
        rules={[
          {
            required: true,
            message: "Input name",
          },
        ]}
      >
        <Input placeholder="Input name" disabled={saving} />
      </Form.Item>
      {hasRealmRole &&
        hasRealmRole(APP_ADMIN) &&
        tenants !== undefined &&
        selected === undefined && (
          <Form.Item
            label="Tenant"
            required
            name="tenant"
            tooltip="Tenant"
            rules={[
              {
                required: true,
                message: "Select tenant",
              },
            ]}
          >
            <Select
              options={tenants.map((t: string) => ({ label: t, value: t }))}
              disabled={saving}
            />
          </Form.Item>
        )}
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={saving}>
          Хадгалах
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SourceTypeForm;
