import { Tag } from "antd";

export const renderAttribute = (obj: any) => {
  if (
    typeof obj === "number" ||
    typeof obj === "string" ||
    typeof obj === "boolean"
  ) {
    return <Tag>{obj}</Tag>;
  } else {
    if (Array.isArray(obj)) {
      return obj.map((o: any, i: number) => {
        return <Tag key={i}>{o}</Tag>;
      });
    } else {
      return Object.keys(obj).map((k: any, i: number) => {
        return (
          <Tag key={i}>
            {k} - {renderAttribute(obj[k])}
          </Tag>
        );
      });
    }
  }
};

export const encodeJsonAttr = (payload: any, keys: string[] = ["info"]) => {
  const _payload = Object.keys(payload).reduce((prev, k: string) => {
    if (keys.includes(k)) {
      return { ...prev, [k]: JSON.stringify(prev[k]) };
    }
    return prev;
  }, payload);
  console.log("payload", payload);
  return _payload;
};

export const decodeJsonAttr = (payload: any, keys: string[] = ["info"]) => {
  return Object.keys(payload).reduce((prev, k: string) => {
    if (keys.includes(k)) {
      return { ...prev, [k]: JSON.parse(prev[k]) };
    }
    return prev;
  }, payload);
};
