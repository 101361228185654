import { Fragment, useCallback, useContext, useReducer } from "react";
import { KeycloakContext } from "../KeycloakProvider";
import {
  useSourceType,
  useSourceTypeDispatch,
} from "../components/hooks/Source";
import {
  Button,
  Divider,
  Drawer,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import Query from "../components/common/Query";
import { Action, Pagination } from "../types";
import SourceTypeForm from "../components/forms/SourceTypeForm";
const { Title } = Typography;

const columns = (props: { pagination?: Pagination; actions?: Action }) => [
  {
    title: "name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "tenant",
    dataIndex: "tenant",
    key: "tenant",
  },
  {
    title: "action",
    key: "action",
    render: (text: any, record: any) => {
      return (
        <Fragment>
          {props.actions?.openEdit && (
            <Button
              onClick={() =>
                props.actions?.openEdit({ type: "edit", value: record })
              }
              size="small"
            >
              Edit
            </Button>
          )}
          {props.actions?.onDelete && (
            <Popconfirm
              placement="top"
              title={`Delete "${record.name}" ?`}
              onConfirm={() => props.actions?.onDelete(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger size="small">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Fragment>
      );
    },
  },
];

const SourceType = (props: any) => {
  const { token, dispatch: dispatchKeycloak } = useContext(KeycloakContext);
  const [state, updateState] = useReducer(
    (state: any, action: any) => {
      switch (action.type) {
        default:
          return { ...state, ...action };
      }
    },
    {
      page: 1,
      max: 10,
      search: "",
    }
  );
  const {
    loading,
    list,
    dispatch: dispatchQuery,
  } = useSourceType({ token, state });
  const dispatch = useCallback(
    (action: any) => {
      if (action.type === "load") {
        updateState({ last: new Date().getTime(), page: 1 });
        dispatchKeycloak?.({ type: "srcType" }); // tenant changed
      } else if (action.type === "merge") {
        //   updateState({ last: new Date().getTime() }); //force reload
        dispatchQuery(action);
      } else if (action.type === "delete") {
        dispatchQuery(action);
        dispatchKeycloak?.({ type: "srcType" }); // tenant changed
      }
    },
    [dispatchQuery, dispatchKeycloak]
  );

  const {
    openEdit,
    closeEdit,
    onSubmit,
    onDelete,
    state: dispatchState,
  } = useSourceTypeDispatch({ token, dispatch });

  return (
    <Fragment>
      <div className="page-header">
        <p className="header-label">
          <Title level={2}>SourceType list</Title>
        </p>
      </div>
      <div className="page-body">
        <div className="page-body-header flex">
          <Space align="start" size="large">
            <Query dispatch={updateState} />
          </Space>
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => openEdit({ type: "add" })}
          >
            Add SourceType
          </Button>
        </div>
        <Table
          rowKey={"id"}
          loading={loading}
          dataSource={list}
          columns={columns({
            actions: {
              openEdit,
              onDelete,
            },
          })}
          pagination={false}
          // pagination={{
          //     current: state?.page,
          //     total: total,
          //     pageSize: state?.per_page,
          //     showTotal: (total, range) => `Нийт ${total}`,
          //     onChange,
          //     onShowSizeChange,
          // }}
        />
        <Drawer
          width={600}
          placement="right"
          closable={true}
          onClose={closeEdit}
          open={dispatchState.show}
        >
          <p
            className="site-description-item-profile-p"
            style={{ marginBottom: 24 }}
          >
            SourceType {dispatchState.selected ? "edit" : "add"}
          </p>
          <Divider />
          {dispatchState.show && (
            <SourceTypeForm {...dispatchState} submit={onSubmit} />
          )}
        </Drawer>
      </div>
    </Fragment>
  );
};

export default SourceType;
