import { Fragment, useContext, useState } from "react";
import { KeycloakContext } from "../KeycloakProvider";
import { Divider, Table, Tabs, Typography } from "antd";
import { useClientRoles, useRealmRoles } from "../components/Hooks";
import ClientFilter from "../components/common/ClientFilter";
const { Title } = Typography;
const columns = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "name",
    dataIndex: "name",
    key: "name",
  },
];

const Role = (props: any) => {
  const { token } = useContext(KeycloakContext);
  const { loading, roles: list } = useRealmRoles({ token });

  const [clientId, setClientId] = useState<string>("");
  const changeClient = (value: string) => {
    setClientId(value);
  };

  const { loading: clientLoading, roles: allClientRoles } = useClientRoles({
    clientId,
    token,
  });

  const [tab, setTab] = useState("realm");
  const onChange = (key: string) => {
    setTab(key);
  };
  return (
    <Fragment>
      <div className="page-header">
        <p className="header-label">
          <Title level={2}>Role list</Title>
        </p>
      </div>
      <div className="page-body">
        <Tabs
          defaultActiveKey="realm"
          items={[
            { key: "realm", label: "Realm roles" },
            { key: "client", label: "Client roles" },
          ]}
          onChange={onChange}
        />
        {/* <Title level={4}>Realm roles</Title> */}
        {tab === "realm" && (
          <Table
            rowKey={"id"}
            loading={loading}
            dataSource={list}
            columns={columns}
            pagination={false}
          />
        )}

        {/* <Divider /> */}
        {/* <Title level={4}>Client roles</Title> */}
        {tab === "client" && (
          <Fragment>
            <ClientFilter onChange={changeClient} />
            <Divider />
            <Table
              loading={clientLoading}
              rowKey={"id"}
              dataSource={allClientRoles}
              columns={columns}
              pagination={false}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Role;
