import { Fragment, useContext } from "react";
import { KeycloakContext } from "../KeycloakProvider";
import { Table, Tag, Typography } from "antd";
import { useClients } from "../components/Hooks";
const { Title } = Typography;
const columns = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "clientId",
    dataIndex: "clientId",
    key: "clientId",
  },
  {
    title: "attributes",
    dataIndex: "attributes",
    render: (_: any, record: any) => {
      console.log("record.attributes", typeof record.attributes);
      if (record.attributes) {
        return Object.keys(record.attributes).map((k: string, i: number) => {
          return (
            <Tag key={i}>
              {k}:{record.attributes[k]}
            </Tag>
          );
        });
      } else {
        return null;
      }
    },
  },
];
const Client = (props: any) => {
  const { token } = useContext(KeycloakContext);

  const { loading, list } = useClients({ token });

  return (
    <Fragment>
      <div className="page-header">
        <p className="header-label">
          <Title level={2}>Client list</Title>
        </p>
      </div>
      <div className="page-body">
        <div className="page-body-header flex">
          {/* <Space align="start" size="large">
                        <Search allowClear placeholder="Хайх үгээ оруулна уу..." onSearch={handleName} enterButton />
                    </Space>                     */}
        </div>
        <Table
          rowKey={"id"}
          loading={loading}
          dataSource={list}
          columns={columns}
          pagination={false}
        />
      </div>
    </Fragment>
  );
};
export default Client;
