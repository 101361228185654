import { Button, Form, Input, Select } from "antd";
import { useContext, useEffect } from "react";
import { KeycloakContext } from "../../KeycloakProvider";
import JsonField from "./JsonField";
import { encodeJsonAttr } from "../Utils";

const SourceForm = (props: any) => {
  const { hasRealmRole, tenants, sites, srcTypes } =
    useContext(KeycloakContext);
  const { selected, saving, submit } = props;

  const [form] = Form.useForm();
  useEffect(() => {
    if (selected) {
      form.setFieldsValue({
        target: selected.id,
        tenant: selected.tenant,
        targetType: selected.targetType,
      });
      // form.setFieldsValue(encodeJsonAttr(selected, ["info"]));
      // form.setFieldsValue({ ...selected, info: JSON.stringify(selected.info) });
    } else {
      form.setFieldsValue({});
    }
  }, [selected, form]);

  return (
    <Form form={form} layout="vertical" onFinish={submit}>
      <Form.Item label="" name="id" hidden={true}>
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item
        label="Source"
        name="src"
        tooltip="Source is sensor id which will be bind to selected entity"
      >
        <Input placeholder="Input source" disabled={saving} />
      </Form.Item>
      {srcTypes !== undefined && (
        <Form.Item
          label="Source type"
          name="srcType"
          tooltip="Source type is sensor data send infra: Ex: UWB - uwb tag, MG - machine guidance"
          required
          rules={[
            {
              required: true,
              message: "Select Source type",
            },
          ]}
        >
          <Select
            options={srcTypes.map((t: any) => ({
              label: t.name,
              value: t.name,
            }))}
            disabled={saving}
          />
        </Form.Item>
      )}

      <Form.Item
        label="Target"
        name="target"
        tooltip="Target is current selected entity id"
      >
        <Input placeholder="Input target" disabled={saving} readOnly={true} />
      </Form.Item>
      <Form.Item label="" name="targetType" hidden={true}>
        <Input placeholder="" disabled={saving} readOnly={true} />
      </Form.Item>
      <Form.Item label="" name="tenant" hidden={true}>
        <Input placeholder="" disabled={saving} readOnly={true} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={saving}>
          Хадгалах
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SourceForm;
