import { message } from "antd";
import { useEffect, useState } from "react";
import { groupMember, searchGroup } from "../../api";

export const useGroup = (props: any) => {
  const { token, last } = props;
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const [root, setRoot] = useState<string>("");
  useEffect(() => {
    const fetchList = async (token: string) => {
      setLoading(true);
      setList([]);
      setRoot("");
      try {
        const result = await searchGroup({ token });

        if (result?.error) {
          // has error
          message.error(<div>{result.error.message} </div>);
        } else {
          setList(result.list || []);
          setRoot(result.root || "");
        }
      } catch (err: any) {
        message.error(<div>{err.message} </div>);
      } finally {
        setLoading(false);
      }
    };

    fetchList(token);
  }, [token, last]);
  return { loading, list, root };
};

export const useGroupMember = (props: any) => {
  const { id, token, last } = props;

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const fetchList = async (id: string, token: string) => {
      setLoading(true);
      setList([]);

      try {
        const result = await groupMember({ id, token });

        if (result?.error) {
          // has error
          message.error(<div>{result.error.message} </div>);
        } else {
          setList(result.list || []);
        }
      } catch (err: any) {
        message.error(<div>{err.message} </div>);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchList(id, token);
    } else {
      setList([]);
    }
  }, [id, token, last]);

  return { loading, list };
};
