import { Fragment, useContext } from "react";
import { KeycloakContext } from "../KeycloakProvider";
import ReactJson from "react-json-view";
import { Typography } from "antd";
const { Title } = Typography;

const Welcome = (props: any) => {
  const { tokenParsed } = useContext(KeycloakContext);
  return (
    <Fragment>
      <div className="page-header">
        <p className="header-label">
          <Title level={2}>Welcome</Title>
        </p>
      </div>
      <div className="page-body">
        My Token
        {tokenParsed && (
          <ReactJson src={tokenParsed} theme="monokai" name={false} />
        )}
      </div>
    </Fragment>
  );
};

export default Welcome;
