import { Fragment, useContext, useEffect, useState } from "react";
import { KeycloakContext } from "../KeycloakProvider";
import { Table, message } from "antd";
import { searchClientRole } from "../api";
import { useParams } from "react-router-dom";

const columns = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "name",
    dataIndex: "name",
    key: "name",
  },
];

const ClientRole = (props: any) => {
  const { token } = useContext(KeycloakContext);
  const { clientId } = useParams();
  const [fetch, updateFetch] = useState(false);
  const [list, updateList] = useState([]);

  useEffect(() => {
    const fetchList = async (token: string, clientId: string) => {
      console.log("clientId", clientId);

      updateFetch(true);
      try {
        const result = await searchClientRole({ id: clientId, token });
        if (result?.error) {
          // has error
          message.error(<div>{result.error.message}</div>);
        } else {
          updateList(result.list || []);
          // updateTotal(result.total || 0)
        }
      } catch (err: any) {
        message.error(<div>{err.message}</div>);
      } finally {
        updateFetch(false);
      }
    };
    if (token && clientId) {
      fetchList(token, clientId);
    }
  }, [clientId, token]);

  return (
    <Fragment>
      <div className="page-header">
        <p className="header-label">Client Roles</p>
      </div>
      <div className="page-body">
        <Table
          rowKey={"id"}
          loading={fetch}
          dataSource={list}
          columns={columns}
          pagination={false}
        />
      </div>
    </Fragment>
  );
};

export default ClientRole;
